<template>
  <div :key="chartId">
    <vue-apex-charts
      :height="height"
      :options="TotalQtyPerPharmacyPieOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'

import { TotalQtyPerPharmacyPieOptions } from '@/assets/chartOptions/market'
import { chartService, apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'TotalQtyPerPharmacyPie',
  components: {
    VueApexCharts,
  },
  setup() {
    const filterStore = useFilterStore()

    return {
      filterStore,
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    }
  },
  props: {
    height: {
      type: String,
      default: 'auto',
    },
  },

  data() {
    return {
      filterStoreUnsubscribe: null,
      TotalQtyPerPharmacyPieOptions,
      data: [],
      series: [],
      refresh: false,
      chartId: 0,
    }
  },
  destroyed() {
    this.filterStoreUnsubscribe()
  },
  mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    this.getMongoChartData()

    // this.$root.$on('mfr-market-share-pie-chart', config => {
    //   this.filterCharts(config)
    // })
  },
  methods: {
    // async filterCharts(config) {
    //   if (this.refresh) {
    //     this.refresh = !this.refresh
    //     this.$root.$emit('reset-mfr-heat-map-filter-by-mfr')
    //     await this.getMongoChartData()
    //   } else {
    //     // this.MfrMarketSharePieChartWatchListOptions.labels = []
    //     console.log(config)
    //     const mfr = config.w.config.labels[config.dataPointIndex].split(' ')[0]
    //     const token = await this.$auth.getTokenSilently()
    //     this.data = await chartService.getChart(
    //       token,
    //       'MfrMarketSharePieChartWatchList',
    //       null,
    //       this.filterStore.dateRange,
    //       [mfr],
    //       '$in',
    //     )

    //     // added to handle null values
    //     this.MfrMarketSharePieChartWatchListOptions.labels = [mfr]
    //     this.data = this.data.map(item => item.value)
    //     this.$root.$emit('mfr-heat-map-filter-by-mfr', mfr)
    //     this.chartId += 1
    //     this.refresh = !this.refresh
    //   }

    //   //   console.log(config)
    // },
    async getMongoChartData() {
      console.log(this.filterStore.dateRange)

      //   this.MfrMarketSharePieChartWatchListOptions.labels = []
      const token = await this.$auth.getTokenSilently()
      console.log(this.filterStore)
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,
      }
      this.data = await apiService.getData(token, 'totalQtyPerPharmacyPie', body)

      // added to handle null values
      this.TotalQtyPerPharmacyPieOptions.labels = await this.data.map(item => (item.label ? item.label : ''))
      this.series = await this.data.map(item => item.value)
      this.chartId += 1
    },
  },
}
</script>
